




















































import {Component, Prop, Vue} from 'vue-property-decorator';
import BaseDialog from '@/components/BaseDialog.vue';
import {BusinessContact} from '@/domain/model/types';
import {Action, Getter} from 'vuex-class';
import ContactsList from '@/components/ContactsList.vue';
import {profileStore} from '@/store/modules/profile';

@Component({
  name: 'dialog-create-new-active-chat',
  components: {ContactsList, BaseDialog},
})
export default class DialogCreateNewActiveChat extends Vue {
  @Prop() value!: boolean;
  @Prop({required: false}) customer: any

  selectedContacts: BusinessContact[] = []
  selectedCustomers: BusinessContact[] = []
  currentStep: string = 'select-customer'
  stepCustomers: string = 'select-customer'
  stepContacts: string = 'select-contact'

  @Getter chatsBusy
  @Getter optedInCustomers;

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get assignedContacts() {
    return profileStore.assignedContacts
  }

  get subtitle() {
    return this.currentStep === this.stepCustomers
        ? 'Select customer to create the chat with'
        : 'Create the chat on behalf of the contact'
  }

  @Action loadCustomers;
  @Action createActiveChat

  onCustomerSelected(contacts: any[]) {
    this.selectedCustomers = contacts;
  }

  onContactSelected(contacts: any[]) {
    this.selectedContacts = contacts;
  }

  async onCreateChat() {

    const chat: any = await this.createActiveChat({
      customerId: this.selectedCustomers[0].id,
      contactId: this.selectedContacts[0].id
    });

    if (!chat) {
      return
    }
    const chatId: string = chat.id
    const isRequest: boolean = chat.case?.status === 1 // chat is in the request state   

    try{

      if (isRequest) {        
        await this.$router.push({
          name: 'inbox-request',
          params: {chatId},
          query: {type: 'inbox', subtype: 'personal'}
        });
      } else {        
        await this.$router.push({
          name: 'active-chat',
          params: {chatId},
          query: {type: 'active', subtype: 'personal', newChat: 'true'}
        });
      }

    }catch(err){    

      console.log('NewActiveChat Error')
      console.log(err)
      if (isRequest) {
        await this.$router.push({
          name: 'inbox-request',
          params: {chatId},
          query: {type: 'inbox', subtype: 'personal'}
        });
      } else {
        await this.$router.push({
          name: 'active-chat',
          params: {chatId},
          query: {type: 'active', subtype: 'personal', newChat: 'true'}
        });
      }
    }
   
    this.show = false
  }

  async created() {
    if (this.customer) {
      this.selectedCustomers.push(this.customer)
      this.currentStep = this.stepContacts
    }
  }
}
